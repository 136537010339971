html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
  background-color: #3a3a3c;
}

#root {
  height: 100%;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form-container {
  width: auto;
  max-width: 80%;
  height: auto;
  background-color: #ffffff;
  display: flex;
  box-sizing: border-box;
  padding: 30px 20px;
  flex-wrap: wrap;
  border-radius: 10px;
  margin-top: 40px;
}

.form-container p {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #7a7a7a;
}

.form-container label {
  font-size: 14px;
  font-weight: bold;
  color: #7a7a7a;
}

@media only screen and (min-width: 1200px) {
  .form-container {
    max-width: 670px;
    padding: 30px 70px;
  }

  .form-container p {
    font-size: 14px;
  }

  .form-container label {
    font-size: 12px;
  }
}

.form-container .form-container__image {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}

.form-field {
  height: auto;
  margin: 0 0 1rem;
}

.form-error {
  margin: 5px 0px 0px;
  padding: 0;
  color: red;
  font-size: 12px;
}

.form-alert {
  padding: 0.75rem 1.25rem;
  border-radius: 5px;
  font-size: 13px;
  font-weight: bold;
}

.form-alert--error {
  color: #d0021b;
  background-color: rgba(208, 2, 27, 0.05);
  border: 1px solid #d0021b;
}

.form-alert--success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

form {
  width: 100%;
  display: block;
  height: auto;
}

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #0a0a0a;
}

input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 0.8rem;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #cacaca;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #fefefe;
  color: #0a0a0a;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
}

input:focus {
  outline: none;
  border-color: #f68c2c;
  border-radius: 5px;
}

.form-button {
  display: flex;
  justify-content: space-around;
}

input[type="submit"] {
  text-align: center;
  line-height: 1;
  width: 200px;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 1em 1em;
  margin: 0 0 1rem 0;
  font-size: 0.9rem;
  background-color: #f68c2c;
  color: #ffffff;
  cursor: pointer;
}

input[type="button"] {
  text-align: center;
  line-height: 1;
  width: 200px;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 1em 1em;
  margin: 0 0 1rem 0;
  font-size: 0.9rem;
  background-color: #f68c2c;
  color: #ffffff;
  cursor: pointer;
}

input[type="submit"][disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

input[type="button"][disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.form-container a {
  color: #0a0a0a;
}

.terms-conditions {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.2px;
  color: #9b9b9b;
  bottom: 0;
}

.terms-conditions a {
  color: #9b9b9b;
}

.auth-message {
  width: 100%;
  text-align: center;
}

.auth-message .auth-message__p1 {
  font-size: 21px;
  font-weight: bold;
  letter-spacing: normal;
  color: #402514;
}

.auth-message .auth-message__p2 {
  font-size: 15px;
  line-height: 1.6;
}

.btn-green {
  background-color: green;
}

.btn-red {
  background-color: red;
}
